import * as _ from 'lodash'
import CoreApi from '../core-api'
import { withBi } from '../utils'
import { EVENTS } from '../../../constants/bi'
import { createPanelDefs } from '../services/panel-definitions'
import { ROLE_FORM } from '../../../constants/roles'
import { BillingPanelReferrer } from '../../../constants/premium'
import { PanelName, NativePanelName } from './consts/panel-names'
import { nativePanelOverrides } from './consts/native-panel-overrides'
import { getPanelDefinitions } from '../../../editor-app/editor-app-impl'
import { getBaseUrl } from '../../../../src/utils/utils'
import { PANEL as ADI_PANEL } from '../../../panels/adi-panel/constants'
import { TABS } from '../../../panels/form-settings-panel/constants'
import { PanelEventName } from './consts/panel-event-names'
import { PanelEvent } from './types'
import { EMAIL_SUBMISSIONS_HELP_ID } from '../../../panels/form-settings-panel/constants/help-ids'

export default class ManagePanelsApi {
  private biLogger: any
  private boundEditorSDK: any
  private editorSDK: any
  private coreApi: CoreApi
  private experiments: any
  private panelEvents: PanelEvent[]

  constructor(boundEditorSDK, editorSDK, coreApi: CoreApi, { experiments, biLogger }) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.biLogger = biLogger
    this.editorSDK = editorSDK
    this.experiments = experiments
    this.panelEvents = []
  }

  public closePanel(panelToken, payload = null) {
    return this.editorSDK.editor.closePanel(panelToken, payload)
  }

  public openHelpPanel(helpId, onOpen = _.noop) {
    onOpen()
    return this.boundEditorSDK.editor.openHelpPanel({ helpId })
  }

  @withBi({ startEvid: EVENTS.PANELS[PanelName.NEW_FORM_SETTINGS].NOTIFICATIONS_TAB_HELP })
  public openEmailSubmissionsHelpPanel(_biData = {}) {
    this.openHelpPanel(EMAIL_SUBMISSIONS_HELP_ID)
  }

  public openAddFormPanel(panelDefinitions) {
    return this.boundEditorSDK.editor.openModalPanel(
      panelDefinitions[PanelName.ADD_FORM]({
        experiments: this.experiments,
        isResponsive: this.coreApi.isResponsive(),
      })
    )
  }

  // _panelName is deprecated. ADI is loading one single page and we need to manage all panels there
  public openAdiPanel(
    _panelName: string,
    formRef: ComponentRef,
    activePanel: ADI_PANEL = ADI_PANEL.EDIT_FORM
  ) {
    return Promise.resolve(
      `https://${getBaseUrl()}/statics/adi-panel.html?id=${formRef.id}&type=${
        formRef.type
      }&state=${activePanel}`
    )
  }

  public openFieldPanel(componentRef: ComponentRef, fieldsPanelToken, _biData = {}) {
    return this.coreApi.isConnectPanel()
      ? this._selectField(componentRef, fieldsPanelToken)
      : this._openFieldSettingsPanel(componentRef, _biData)
  }

  private _selectField(componentRef: ComponentRef, fieldsPanelToken) {
    this.closePanel(fieldsPanelToken)
    return this.boundEditorSDK.selection.selectComponentByCompRef({ compsToSelect: [componentRef] })
  }

  @withBi({ startEvid: EVENTS.PANELS.fieldSettingsPanel.OPEN_PANEL })
  private _openFieldSettingsPanel(componentRef: ComponentRef, _biData = {}) {
    return this._openFieldPanel(componentRef)
  }

  private async _openFieldPanel(componentRef: ComponentRef) {
    const [msid, { config }] = await this._fetchConfigAndMsid(componentRef)
    const mode = await this.boundEditorSDK.info.getEditorMode()

    return this.boundEditorSDK.editor.openComponentPanel(
      createPanelDefs(msid)[PanelName.FIELD_SETTINGS](
        componentRef,
        { config, componentRef },
        { mode, experiments: this.experiments }
      )
    )
  }

  public async openContactSyncPanel(componentRef: ComponentRef) {
    const msid = await this.coreApi.getMetaSiteId()

    const panelDefinition = createPanelDefs(msid)[PanelName.CONTACT_SYNC_PANEL](componentRef, {
      componentRef,
    })
    return this.boundEditorSDK.editor.openComponentPanel(panelDefinition)
  }

  public async openSettingsPanel(childRef: ComponentRef, displayedTab = TABS.MAIN) {
    const componentRef = await this.coreApi.findComponentByRole(childRef, ROLE_FORM)
    const [msid, { config }] = await this._fetchConfigAndMsid(componentRef)

    return this.boundEditorSDK.editor.openComponentPanel(
      createPanelDefs(msid)[PanelName.NEW_FORM_SETTINGS](componentRef, {
        config,
        componentRef,
        displayedTab,
      })
    )
  }

  public async openGetSubscribersFirstTimePanel(): Promise<any> {
    const panelDefinitions = getPanelDefinitions()
    const dontShowAgain = await this.coreApi.firstTimePanel.getSubscribersHideFirstTimePanel()

    if (dontShowAgain) {
      return
    }

    return this.boundEditorSDK.editor.openToolPanel(panelDefinitions[PanelName.FIRST_TIME]())
  }

  @withBi({ startEvid: EVENTS.PANELS.addFieldPanel.OPEN_PANEL })
  public async openAddFieldPanel(formContainerRef, panelToken, _biData = {}) {
    const [msid, { config }] = await this._fetchConfigAndMsid(formContainerRef)

    return this.editorSDK.editor.updatePanelOptions(
      panelToken,
      createPanelDefs(msid)[PanelName.ADD_FIELD](formContainerRef, {
        config,
        componentRef: formContainerRef,
      })
    )
  }

  @withBi({ startEvid: EVENTS.PANELS.upgradeAlertPanel.ACTION_CLICK })
  public closeUpgradeAlertPanel(token, _biData = {}) {
    return this.closePanel(token)
  }

  public async openPremiumBillingPanel(
    componentRef: ComponentRef,
    { referrer, alertType }: { referrer: BillingPanelReferrer; alertType: string }
  ) {
    const esi = await this.coreApi.getEditorSessionId()
    return this._openUpgradeAlertPanel(componentRef, alertType, {
      startBi: {
        form_comp_id: componentRef.id,
        esi,
        origin: referrer,
      },
    })
  }

  @withBi({ startEvid: EVENTS.PANELS.upgradeAlertPanel.OPEN_PANEL })
  public async openAddFormPremiumBillingPanel(alertType, _biData = {}) {
    const msid = await this.coreApi.getMetaSiteId()
    const componentRef = undefined

    return this.boundEditorSDK.editor.openModalPanel(
      createPanelDefs(msid)[PanelName.UPGRADE_ALERT](componentRef, {
        alertType,
      })
    )
  }

  @withBi({ startEvid: EVENTS.PANELS.manageSubscribersPanel.OPEN_PANEL })
  public openManageSubscribersPanel(panelDefinitions, _biData = {}) {
    return this.boundEditorSDK.editor.openModalPanel(
      panelDefinitions[PanelName.FORM_MANAGE_SUBSCRIBERS]()
    )
  }

  @withBi({ startEvid: EVENTS.PANELS.manageSubscribersPanel.OPEN_PANEL })
  public openDefaultManageSubscribersPanel(msid, _biData = {}) {
    return this.boundEditorSDK.editor.openModalPanel(
      createPanelDefs(msid)[PanelName.FORM_MANAGE_SUBSCRIBERS]()
    )
  }

  public async openMemberSignupPagePanel() {
    const currentPageRef = await this.editorSDK.document.pages.getCurrent()
    return this.boundEditorSDK.editor.openPagesPanel({ pageRef: currentPageRef })
  }

  public openBusinessManagerPanel(entry) {
    return this.boundEditorSDK.editor.openDashboardPanel({ closeOtherPanels: false, url: entry })
  }

  @withBi({ startEvid: EVENTS.PANELS.addFieldPanel.SELECT_FIELD_TO_ADD })
  public openBlockedFieldAlert(componentRef: ComponentRef, { referrer, alertType }, _biData = {}) {
    return this.openPremiumBillingPanel(componentRef, { referrer, alertType })
  }

  public async openDynamicFieldSettingsPanel(
    componentRef: ComponentRef,
    onOpen: ({}) => void,
  ) {
    const { role, controllerRef, config } = await this.coreApi.getComponentConnection(componentRef)
    const formComponentRef = await this.coreApi.findConnectedComponent(controllerRef, ROLE_FORM)

    const options = {
      componentRef,
      ..._.get(nativePanelOverrides, role, {}),
    }

    this.boundEditorSDK.editor.openNativeComponentPanel(NativePanelName.SETTINGS, options)
    onOpen(_.merge({}, config, { formId: formComponentRef.id }))
  }

  public async openComponentPanel(
    componentRef: ComponentRef,
    panelName: PanelName,
    panelDefinitions,
    onOpen,
    externalData?
  ) {
    const { role, controllerRef, config } = await this.coreApi.getComponentConnection(componentRef)
    const formComponentRef =
      role == ROLE_FORM
        ? componentRef
        : await this.coreApi.findConnectedComponent(controllerRef, ROLE_FORM)
    const { plugins } = await this.coreApi.getFormConfigData(formComponentRef)
    config.plugins = plugins
    const isSiteSaved = await this.boundEditorSDK.info.isSiteSaved()
    if (!isSiteSaved) {
      await this.boundEditorSDK.editor.save()
      const emailId = await this.coreApi.getOwnerEmailId()

      await this.coreApi.setComponentConnection(formComponentRef, { emailId })
      config.emailId = emailId
    }
    const mode = await this.boundEditorSDK.info.getEditorMode()
    this.boundEditorSDK.editor.openComponentPanel(
      panelDefinitions[panelName](
        componentRef,
        _.merge({}, { config, componentRef }, externalData),
        {
          mode,
          experiments: this.experiments,
          isResponsive: this.coreApi.isResponsive(),
        },
      ),
    )
    onOpen(_.merge({}, config, { formId: formComponentRef.id }))
  }

  @withBi({ startEvid: EVENTS.PANELS.upgradeAlertPanel.OPEN_PANEL })
  private async _openUpgradeAlertPanel(
    componentRef: ComponentRef,
    alertType: string,
    _biData = {}
  ) {
    const [msid, { config }] = await this._fetchConfigAndMsid(componentRef)
    return this.boundEditorSDK.editor.openModalPanel(
      createPanelDefs(msid)[PanelName.UPGRADE_ALERT](componentRef, {
        config,
        componentRef,
        alertType,
      })
    )
  }

  private async _openAddFormUpgradeAlertPanel(alertType: string, _biData = {}) {
    const msid = await this.coreApi.getMetaSiteId()
    const componentRef = undefined
    return this.boundEditorSDK.editor.openModalPanel(
      createPanelDefs(msid)[PanelName.UPGRADE_ALERT](componentRef, {
        alertType,
      })
    )
  }

  private _fetchConfigAndMsid(componentRef: ComponentRef): Promise<[string, { config }]> {
    return Promise.all([
      this.coreApi.getMetaSiteId(),
      this.coreApi.getComponentConnection(componentRef),
    ])
  }

  public openPublishSitePopup(componentRef: ComponentRef, msid: string) {
    return this.boundEditorSDK.editor.openModalPanel(
      createPanelDefs(msid)[PanelName.PUBLISH_SITE_POP_UP](componentRef)
    )
  }

  @withBi({ startEvid: EVENTS.PANELS[PanelName.PAYMENT_WIZARD].PAYMENT_WIZARD_DISPLAYED })
  public openPaymentWizardPanel(componentRef: ComponentRef, msid, _biData = {}) {
    return this.boundEditorSDK.editor.openModalPanel(
      createPanelDefs(msid)[PanelName.PAYMENT_WIZARD]({ componentRef })
    )
  }

  @withBi({
    startEvid: EVENTS.PANELS[PanelName.DELETE_PAYMENT_ALERT].REMOVE_PAYMENT_POPUP_DISPLAYED,
  })
  public openDeletePaymentPopup(componentRef: ComponentRef, msid: string, _biData = {}) {
    return this.boundEditorSDK.editor.openModalPanel(
      createPanelDefs(msid)[PanelName.DELETE_PAYMENT_ALERT](componentRef, { componentRef })
    )
  }

  public openClosePaymentWizardAlertPopup(componentRef: ComponentRef, msid: string) {
    return this.boundEditorSDK.editor.openToolPanel(
      createPanelDefs(msid)[PanelName.CLOSE_PAYMENT_WIZARD_ALERT](componentRef, { componentRef })
    )
  }

  @withBi({ startEvid: EVENTS.PANELS[PanelName.NEW_FORM_SETTINGS].CLICK_EDIT_FORM_NAME })
  public openRenameFormToolPanel(componentRef: ComponentRef, msid: string, _biData = {}) {
    return this.boundEditorSDK.editor.openToolPanel(
      createPanelDefs(msid)[PanelName.RENAME_FORM_PANEL](componentRef, { componentRef })
    )
  }

  @withBi({ startEvid: EVENTS.PANELS[PanelName.MANAGE_STEPS].OPEN_PANEL })
  public async openManageStepsPanel(componentRef: ComponentRef, _biData = {}) {
    const [msid, { config }] = await this._fetchConfigAndMsid(componentRef)

    return this.boundEditorSDK.editor.openComponentPanel(
      createPanelDefs(msid)[PanelName.MANAGE_STEPS](componentRef, { config, componentRef })
    )
  }

  public registerPanelEvent(panelEvent: PanelEvent) {
    this.panelEvents.push(panelEvent)
  }

  public unregisterAllPanelEvents(panelToken: string) {
    this.panelEvents = this.panelEvents.filter(event => event.panelToken != panelToken)
  }

  public triggerPanelEvent(eventName: PanelEventName, payload) {
    this.panelEvents
      .filter(event => event.eventName === eventName)
      .forEach(event => event.callback(payload))
  }

  public getAllPanelEvents() {
    return this.panelEvents
  }
}
