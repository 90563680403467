import translations from '../../../utils/translations'
import { CRM_TAGS, CRM_TYPES, crmTypesTags } from '../../../constants/crm-types-tags'
import * as _ from 'lodash'
import { getFieldProperties } from '../preset/fields/field-types-data'
import { createSuffixedName } from '../../../utils/utils'
import { ComponentConfig, FormField } from '../../../constants/api-types'
import { COMPONENT_TYPES } from '../../../constants/component-types'

export const getFieldsLeft = (fieldsInFormLength: number, limit: number) => limit > -1 ? limit - fieldsInFormLength : Infinity

export const getDuplicatedFieldConfig = (
  fields: Partial<FormField>[],
  config: ComponentConfig
): ComponentConfig => {
  const duplicatedFieldName = translations.t('manageFields.duplicatedFormName', {
    name: config.crmLabel,
  })
  const newCrmLabel = createSuffixedName(
    _.map(fields, (field: Partial<FormField>) => field.crmLabel),
    duplicatedFieldName
  )
  const isCrmTypeTag = !!crmTypesTags[config.crmType]

  return _.assign({}, config, {
    collectionFieldType: getFieldProperties(config.fieldType).collectionFieldType,
    crmLabel: newCrmLabel,
    crmTag: isCrmTypeTag ? CRM_TAGS.OTHER : config.crmTag,
    crmType: isCrmTypeTag ? config.crmType : CRM_TYPES.CUSTOM_FIELD,
    customFieldId: undefined,
    customFieldName: undefined,
    collectionFieldKey: createSuffixedName(
      _.map(fields, 'collectionFieldKey'),
      _.camelCase(newCrmLabel),
      ''
    ),
  })
}

export const getDefaultLabel = ({ titleText, buttonLabel, label, placeholder, fieldType }) => {
  const componentType = getFieldProperties(fieldType).componentType
  switch (componentType) {
    case COMPONENT_TYPES.FILE_UPLOADER:
      return label || buttonLabel
    case COMPONENT_TYPES.SIGNATURE_INPUT:
      return titleText
    case COMPONENT_TYPES.RATING:
      return
    default:
      return label || _.get(placeholder, 'text') || placeholder
  }
}

const MAXIMUM_FIELD_NAME_LENGTH = 30

export const getDefaultFieldName = (data: any, config: ComponentConfig): string => {
  const {
    crmLabel,
    fieldType
  } = config

  const {
    placeholder,
    titleText,
    buttonLabel,
    label,
    placeholderLabel,      
  } = data

  const defaultPlaceholder: string = placeholder || placeholderLabel

  const defaultLabel: string = getDefaultLabel({
    titleText,
    buttonLabel,
    label,
    placeholder: defaultPlaceholder,
    fieldType,
  })

  return (defaultLabel || crmLabel).slice(0, MAXIMUM_FIELD_NAME_LENGTH)
}
